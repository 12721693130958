import type { BrandTheme, PartialBrandTheme } from '../../types/BrandTheme'
import { brandTheme } from '../../types/BrandTheme'
import { merge } from 'theme-ui'
import { dirname } from 'path'
import { icons } from './icons'

// const oldprimary = '#8cd600'
import './theme.css'

export const elkjoptheme: BrandTheme = merge.all<BrandTheme, PartialBrandTheme>(
  brandTheme,
  {
    branding: {
      brand: 'ELKJOP',
      themedir: dirname(new URL(import.meta.url).pathname),
      metaTitle: 'Elkjop',
      staticDir: 'elkjop',
      width: 89,
      height: 32,
      learnMoreLinkWaitlist: 8031150,
      learnMoreLinkSearch: 8041921,
    },
    icons,
    fonts: {
      body: "Inter, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      heading: "Inter, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      monospace: 'Menlo, monospace',
    },
    fontSizes: [12, 13, 15, 16, 19, 24, 32, 48, 64],
    fontWeights: {
      light: 300,
      body: 400,
      heading: 700,
      bold: 500,
    },
    colors: {
      text: '#333',
      background: '#fff',
      primary: '#11155c',
      primaryText: '#fff',
      secondary: '#fff',
      secondaryText: '#58585B',
      focus: '#11155c',
      focusDark: '#fff',
      accent: '#6aa334',
      accentText: '#fff',
      border: '#ddd',
      gray: '#45434c',
      onboarding: '#6aa334',
    },
    buttons: {
      primary: {
        lineHeight: '34px',
        fontSize: 2,
        border: '1px solid #DDDDDE',
        textDecoration: 'none',
        fontWeight: 'light',
        borderRadius: '4px',
        py: 0,
        px: 2,
        minWidth: '128px',
        width: 'auto',
        '&:not(:disabled):hover': {
          filter: 'brightness(1)',
          opacity: 1,
        },
      },
      secondary: {
        variant: 'buttons.primary',
        color: 'secondaryText',
        '&:not(:disabled):hover': {
          filter: 'brightness(0.9)',
          opacity: 0.9,
        },
      },
      error: {
        '&:not(:disabled):hover': {
          filter: 'brightness(0.9)',
          opacity: 0.9,
        },
      },
      plain: {
        '&:not(:disabled):hover': {
          filter: 'brightness(0.9)',
          opacity: 0.9,
        },
      },
      download: {
        backgroundColor: 'background',
        '&:not(:disabled):hover': {
          backgroundColor: 'background',
        },
      },
      openWebInterface: {
        p: '4px 8px',
        '&:not(:disabled):hover': {
          border: '1px solid rgba(0,0,0,.15)',
          backgroundColor: 'background',
        },
      },
      replyButton: {
        color: 'var(--color-text)',
      },
      replyButtonDarkMode: {
        color: 'background',
      },
      smartSearchPrimaryButton: {
        bg: 'primary',
        color: 'background',
        border: '1px solid primary',
        '--focus-color': '#E9F1E1',
      },
      smartSearchLightButton: {
        bg: '#E9F1E1',
      },
    },
    forms: {
      checkbox: {
        fontSize: '11px',
        borderRadius: '3px',
        border: '1px solid',
      },
    },
    cards: {
      compact: {
        // color: 'rgba(69,67,76,.8)',
      },
      photoUploadSuccess: {
        '--action-button-text-opacity': 1,
      },
      photoUpload: {
        '--action-button-text-opacity': 1,
      },
    },
    links: {
      appNavbarButton: {
        '--_color': 'rgba(0,0,0,0.65)',
      },
      publicFooterLink: {
        color: '#6E6C8D',
      },
      navbar: {
        '--links-navbar-icon-size': '24px',
        fontSize: [1, 3],
        '&[href]': {
          color: 'rgba(0,0,0,0.5)',
          '&[aria-current],&.force-active': {
            color: 'black',
          },
          '&:hover': {
            color: 'rgba(0,0,0,0.9)',
          },
        },
      },
    },
    layout: {
      appHeaderHamburger: {
        '--hamburger-opacity': 0.6,
        pr: '16px',
        '&:hover': {
          '--hamburger-opacity': 1,
        },
      },
      appTopToolbar: {
        fontSize: [1, 3, 3],
      },
      smartPhotoBannerContainer: {
        bg: '#E9F1E1',
      },
      appFooterPublicContainer: {
        background: 'white',
      },
    },
    styles: {
      avatar: {
        fontSize: 1,
        fontWeight: 'body',
        width: '36px',
        height: '36px',
        lineHeight: '36px',
      },
      h1: {
        fontSize: '23px',
        fontWeight: 'heading',
        padding: 0,
        lineHeight: '18px',
      },
      h2: {
        fontSize: '17px',
        padding: 0,
        lineHeight: '24px',
      },
      p: {
        fontSize: '15px',
      },
      a: {
        '&[href]': {
          color: 'elkjopLink',
        },
      },
      downloadPageHeader: {
        color: 'black',
      },
      downloadSvg: {
        color: 'primary',
      },
      fileIcon: {
        '--file-icon-border-color': '#b3b3b3',
        '--folder-icon-fill': '#f0f0f0',
        //filter: 'grayscale(1)',
      },
      fileinfo: {
        fontWeight: 300,
      },
      newAlbumP: {
        color: '#45434c',
        opacity: 0.8,
      },
      addMoreSvg: {
        width: 4,
        height: 4,
      },
      albumInfo: {
        color: '#45434c',
        opacity: 0.8,
      },
      timelineHeading: {
        fontSize: '20px',
        fontWeight: 500,
      },
      progress: {
        color: 'accent',
        backgroundColor: 'border',
      },
      progressBar: {
        backgroundColor: 'accent',
      },
      storageProgressBar: {
        backgroundColor: 'accent',
      },
      uploadProgress: {
        backgroundColor: 'rgb(30, 25, 84)',
      },
      defaultProfilePicSvg: {
        color: '#6AA334',
      },
    },
  },
)
export default elkjoptheme
